import { NOTIFICATIONS_UPDATE_STATUS } from "../../actions/sockets/notifications";
// import { AUTH_LOGOUT } from "../../actions/auth";

const state = {
  disabled_notification: localStorage.getItem("disabled_notification"),
};

const getters = {
  getStompNotificationsTopic: function(state, getters, rootState, rootGetters) {
    return rootGetters.isStompLoaded
      ? rootState.socketsStomp.configurations.notificationsTopic
      : "";
  },
  isNotificationDisabled: function(state) {
    return state.disabled_notification === "true";
  },
};

const actions = {};

const mutations = {
  [NOTIFICATIONS_UPDATE_STATUS]: (state, params) => {
    state.disabled_notification = params.status ? "true" : "false";
    localStorage.setItem("disabled_notification", state.disabled_notification);
  },
  // [AUTH_LOGOUT]: (state) => {
  //   state.disabled_notification = "false";
  //   localStorage.removeItem("disabled_notification");
  // },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
