import Vue from "vue";
import VueRouter from "vue-router";
import Tracking from "../views/Tracking.vue";
import TrackingSecured from "../views/TrackingSecured.vue";
import PageNotFound from "../views/PageNotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:id",
    name: "Tracking",
    component: Tracking,
  },
  {
    path: "/secured/:id",
    name: "tracking.secured",
    component: TrackingSecured,
  },
  { path: "*", component: PageNotFound }
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
