import Vue from "vue";
import Vuex from "vuex";
import socketsTracking from "./modules/sockets/tracking";
import socketsNotifications from "./modules/sockets/notifications";
import socketsStomp from "./modules/sockets/stomp";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  state: {
    isLoading: false,
  },
  getters: {
    isLoading: (state) => state.isLoading,
  },
  mutations: {
    START_LOADING: (state) => {
      state.isLoading = true;
    },
    STOP_LOADING: (state) => {
      state.isLoading = false;
    },
  },
  modules: {
    socketsTracking,
    socketsNotifications,
    socketsStomp,
  },
  strict: debug,
});
