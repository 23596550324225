<template>
  <loading v-if="isLoading" />
  <MapTracking v-else />
</template>

<script>
import MapTracking from "../components/map/Tracking/Tracking.vue";
import { mapState } from "vuex";
import {
  SET_ORDER_TRACKING_GUID,
  GET_ORDER_TRACKING_REQUEST,
} from "actions/sockets/tracking";
export default {
  name: "App",
  components: {
    MapTracking,
  },
  computed: {
    // ...mapGetters(['isBreadShow','addPrimaryColor']),
    ...mapState({
      isLoading: (state) => state.isLoading,
    }),
  },
  created: function() {
    let guid = this.$route.params.id;
    this.$store.commit(SET_ORDER_TRACKING_GUID, {
      guid,
    });
    this.$store.dispatch(GET_ORDER_TRACKING_REQUEST).catch((error) => {
      swal({
        icon: "error",
        text: error.response.data.errors.error,
        buttons: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
      }).then(function() {
        window.location.reload(true);
      });
    });
  },
};
</script>
