import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import "sweetalert";
import "./prototypes/prototypes";
import "./components/global/global";
import "bootstrap/dist/js/bootstrap.min";
import "./sass/argon.scss";
import "./sass/main.scss";
import router from "./router";
import i18n from './i18n'

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
