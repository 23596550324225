import {
  SET_ORDER_TRACKING_GUID,
  GET_ORDER_TRACKING_REQUEST,
  GET_ORDER_TRACKING_SUCCESS,
  GET_ORDER_TRACKING_ERROR,
  TRACKING_ADD_UPDATE_CAPTAIN,
  TRACKING_UPDATE_CAPTAIN_STATUS,
  SET_ORDER_TRACKING_SECURED_TOKEN,
  GET_ORDER_TRACKING_SECURED_REQUEST,
} from "../../actions/sockets/tracking";
import axioses from "@/axios/config";

const state = {
  captains: localStorage.getItem("tracking_captains")
    ? JSON.parse(localStorage.getItem("tracking_captains"))
    : {},
  mapPoints: {},
  configurations: {},
  guid: '',
  token: ''
};

const getters = {
  getStompConnectionUrl: function(state) {
    return state.configurations.connectionUrl;
  },
  getStompConnectionsTopic: function(state) {
    return state.configurations.connectionTopic;
  },
  getStompConnectionToken: function(state) {
    return state.configurations.connectionToken;
  },
  getCaptains: function(state) {
    return state.captains;
  },

  getMapPoints: function(state) {
    return state.mapPoints;
  },
};

const actions = {
  [GET_ORDER_TRACKING_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(GET_ORDER_TRACKING_REQUEST);
      commit('START_LOADING', null, { root: true });
      axioses.base
        .get(`/orders/tracking/${state.guid.guid}`)
        .then((response) => {
          commit(GET_ORDER_TRACKING_SUCCESS, response.data);
          commit('STOP_LOADING', null, { root: true });
          resolve();
        })
        .catch((error) => {
          commit(GET_ORDER_TRACKING_ERROR);
          commit('STOP_LOADING', null, { root: true });
          reject(error);
        });
    });
  },
  [GET_ORDER_TRACKING_SECURED_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(GET_ORDER_TRACKING_SECURED_REQUEST);
      commit('START_LOADING', null, { root: true });
      axioses.base
          .get(`/orders/tracking/secured/${state.token}`)
          .then((response) => {
            commit(GET_ORDER_TRACKING_SUCCESS, response.data);
            commit('STOP_LOADING', null, { root: true });
            resolve();
          })
          .catch((error) => {
            commit(GET_ORDER_TRACKING_ERROR);
            commit('STOP_LOADING', null, { root: true });
            reject(error);
          });
    });
  },
};

const mutations = {
  [SET_ORDER_TRACKING_GUID]: (state,guid) => {
    state.guid = guid;
  },
  [SET_ORDER_TRACKING_SECURED_TOKEN]: (state,params) => {
    state.token = params.token;
  },
  [TRACKING_ADD_UPDATE_CAPTAIN]: (state, captain) => {
    let captainAttributes = {
      accuracy: captain.accuracy,
      captainId: captain.captainId,
      date: captain.date,
      latitude: captain.latitude,
      longitude: captain.longitude,
      speed: captain.speed,
      time: captain.time,
      connected: !!captain.connected,
    };
    if (state.captains[captain.captainId]) {
      state.captains[captain.captainId] = {
        ...state.captains[captain.captainId],
        ...captainAttributes,
      };
    } else {
      state.captains[captain.captainId] = captainAttributes;
    }

    localStorage.setItem("tracking_captains", JSON.stringify(state.captains));
  },
  [TRACKING_UPDATE_CAPTAIN_STATUS]: (state, captain) => {
    if (state.captains[captain.captainId]) {
      state.captains[captain.captainId] = {
        ...state.captains[captain.captainId],
        ...{
          connected: captain.connected,
          // date: captain.date,
        },
      };
      localStorage.setItem("tracking_captains", JSON.stringify(state.captains));
    }
  },
  [GET_ORDER_TRACKING_REQUEST]: (state) => {
    state.status = "loading";
  },
  [GET_ORDER_TRACKING_SECURED_REQUEST]: (state) => {
    state.status = "loading";
  },
  [GET_ORDER_TRACKING_SUCCESS]: (state, responseData) => {
    let config = {
      connectionUrl: responseData.connectionUrl,
      connectionTopic: responseData.connectionTopic,
      connectionToken: responseData.connectionToken
    };
    state.configurations = config;
    state.mapPoints = responseData.points;
  },
  [GET_ORDER_TRACKING_ERROR]: (state) => {
    // state.status = "error";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
