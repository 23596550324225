import { STOMP_UPDATE } from "../../actions/sockets/stomp";
// import { AUTH_LOGOUT } from "../../actions/auth";

const state = { configurations: {} };

const getters = {
  getStomp: (state) => state.configurations,
  isStompLoaded: (state) =>
    typeof state.configurations === "object" &&
    !!state.configurations.connectionUrl,
};

const actions = {};

const mutations = {
  [STOMP_UPDATE]: (state, stomp) => {
    state.configurations = stomp;
  },
  // [AUTH_LOGOUT]: (state) => {
  //   state.configurations = {};
  // },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
